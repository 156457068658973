import React, { useCallback, useMemo, useRef } from 'react';
import { InfoCard } from '../../../../common/InfoCard';

import { FormGroup } from '../../../../FormElements/FormGroup';
import { TextInput } from '../../../../FormElements/TextInput';
import { useT, T } from '@transifex/react';

import {
  budgetFormatter,
  isEntryNaN,
  setLabelPricePerMessage,
  percentageOfBudget,
  carbonOffsetInTones,
  calculateDailyBudget,
  budgetParser,
  numberToCurrency,
  stringToNumber,
} from '../../../../../utils/budget';

import { CARBON_OFFSET_IMPACT_PERCENTAGE } from '../../../../../constants/values';

import s from './RegularCampaign.module.scss';

import { constructTestId } from '../../../../../utils/test-ids/';
import { CREATE_CAMPAIGN_PAGE } from '../../../../../constants/test-ids/create-campaing-sections/create-campaign-sections';
import { useScroll } from '../../../../../hooks/useScroll';
import Label from '../../../../FormElements/Label/Label';
import { calculateCampaignDuration } from '../../../../../utils/date/dateHelpers';
import { Controller } from 'react-hook-form';
import { Radio } from '../../../../FormElements/Radio';

const SECTION = CREATE_CAMPAIGN_PAGE.TIMING_AND_BUDGET;

const RegularCampaign = ({
  brandInfo,
  sponsorshipSetDraft,
  control,
  errors,
  register,
  isBudgetNaN,
  budgetData,
  handleBudgetAllocationModal,
  videoAdPricingCheckboxes,
  setStickyMenuItem,
  setValue,
  watchBudget,
  watchStartDate,
  watchEndDate,
  watchIncentive,
  charityName,
  adSetIsLocked,
}) => {
  const budgetRef = useRef();

  useScroll([budgetRef], 0.85, setStickyMenuItem);
  const t = useT();
  const handleChangeIncentive = useCallback(
    (e, onChange) => {
      const { value } = e.target;

      const selectedIncentive = videoAdPricingCheckboxes.filter((option) => {
        return option.videoMaxTimeInSeconds.toString() === value;
      })[0];

      onChange(selectedIncentive);
    },
    [videoAdPricingCheckboxes]
  );

  const campaignDuration = useMemo(
    () => calculateCampaignDuration(watchStartDate, watchEndDate),
    [watchStartDate, watchEndDate]
  );

  const dailyBudget = useMemo(() => {
    return budgetFormatter(
      calculateDailyBudget(campaignDuration, watchBudget ? budgetParser(watchBudget.toString()) : 0)
    );
  }, [campaignDuration, watchBudget]);

  const selectedCustomIncentive = useMemo(
    () =>
      !videoAdPricingCheckboxes.find(
        (option) => option.videoMaxTimeInSeconds === watchIncentive?.videoMaxTimeInSeconds
      ),
    [watchIncentive, videoAdPricingCheckboxes]
  );

  return (
    <>
      <div>
        <div className={s['budget']} ref={budgetRef} id="budget">
          <FormGroup
            title={t('Total Budget')}
            subtitle={t('Define how much you would like to spend in this campaign.')}
            required
          >
            <TextInput
              data-testid={constructTestId(SECTION, 'total-budget-input')}
              type="text"
              id="budget"
              name="budget"
              label={`${brandInfo.country.currencyCode} ${brandInfo.country.currencySymbol}`}
              // formValue={budget && budgetFormatter(budget)}
              onChange={(value) => {
                setValue('budget', budgetFormatter(budgetParser(value)));
              }}
              watchValue={watchBudget}
              register={register}
              error={errors.budget}
            />
          </FormGroup>
        </div>
        <div className={s['budget']}>
          <FormGroup
            title={t('Daily Budget')}
            subtitle={t(
              'This is automatically calculated from your total budget and campaign start & end dates.'
            )}
          >
            <Label
              data-testid={constructTestId(SECTION, 'daily-budget-input')}
              type="text"
              id="dailyBudget"
              name="dailyBudget"
              value={dailyBudget || '-'}
              label={`${brandInfo.country.currencyCode} ${brandInfo.country.currencySymbol}`}
              error={errors.dailyBudget}
            />
          </FormGroup>
        </div>
        <div className={s['video']}>
          <FormGroup title={t('Video Length')}>
            <div className={s['video__checkboxes-wrap']}>
              <Controller
                control={control}
                name="incentive"
                render={({ field: { onChange, value } }) =>
                  videoAdPricingCheckboxes.map((item) => (
                    <div className={s['video__checkbox']} key={item.videoMaxTimeInSeconds}>
                      <Radio
                        testId={constructTestId(SECTION, `video-length-radio-${item.label}`)}
                        name={item.videoMaxTimeInSeconds}
                        value={item.videoMaxTimeInSeconds}
                        label={`${item.videoMinTimeInSeconds} - ${
                          item.videoMaxTimeInSeconds
                        } sec (${setLabelPricePerMessage(item, videoAdPricingCheckboxes)})`}
                        checked={value?.maxVideoLength ?? value?.videoMaxTimeInSeconds}
                        onChange={(e) => {
                          handleChangeIncentive(e, onChange);
                        }}
                        disabled={adSetIsLocked}
                      />
                    </div>
                  ))
                }
              />
            </div>
          </FormGroup>
          {selectedCustomIncentive && (
            <div className={s['custom-incentive']}>
              <T
                src="*The selected incentive for this Ad set (
              {pricePerMessage) is not listed on the above. This
              may caused by a pricing change on your brand account."
                pricePerMessage={numberToCurrency(watchIncentive?.pricePerMessage)}
              />
            </div>
          )}
        </div>
      </div>

      <div className={s['delivery']}>
        <FormGroup title={t('Delivery')}>
          <div className={s['delivery__content']}>
            <span className={s['highlighted']}>
              {isBudgetNaN ? budgetFormatter(budgetData.guarantied) : '0'}
            </span>{' '}
            {t(
              'target consumers will opt-in to watch your full video ad, and engage by answering your questions.'
            )}
          </div>
          <div className={s['delivery__accented']}>
            {`${t('Cost per consumer relationship and session is')} ${numberToCurrency(
              watchIncentive?.pricePerMessage
            )}'. '${t(
              'This includes fully completed video views, engagement, responses to all questions, and call to action images encouraging click-through to drive conversions, installs, social following, or visits to your website.'
            )}`}
          </div>
        </FormGroup>

        {/* {brandInfo.role === 'WeAre8' && (
          <div className={s['allocation-custom']}>
            <div
              className={s['popup']}
              onClick={handleBudgetAllocationModal}
              data-testid={constructTestId(SECTION, 'edit-budget-alloc-btn')}
            >
              Edit budget allocation
            </div>
          </div>
        )} */}
      </div>

      <div className={s['allocation']}>
        <InfoCard>
          <div className={s['allocation__title']}>{t('Budget Allocation:')}</div>
          <div className={s['allocation__content']}>
            <span className={s['bold']}>{t('8 Citizen Payments:')}</span>
            {watchBudget && isBudgetNaN
              ? ` 
                    ${budgetFormatter(budgetData?.guarantied)} x ${
                  isEntryNaN(watchIncentive?.netCashReward) ? +watchIncentive?.netCashReward : '-'
                } = ${numberToCurrency(budgetData.payments)}
                     ${t('direct payments to 8Citizens.')}`
              : ''}
          </div>
          <div className={s['allocation__content']}>
            <span className={s['bold']}>{t('Charity Donations')}:</span>
            {watchBudget && isBudgetNaN
              ? ` 
                    ${budgetFormatter(budgetData?.guarantied)} x ${
                  isEntryNaN(watchIncentive?.charityDonation)
                    ? +watchIncentive?.charityDonation
                    : '-'
                } = ${numberToCurrency(budgetData?.donations)}
                     ${t('donated to your selected charity')} - ${
                  sponsorshipSetDraft.selectedCharity.name || charityName || ''
                }.`
              : ''}
          </div>
          <div className={s['allocation__content']}>
            <span className={s['bold']}>{t('Distribution Costs: ')}</span>
            {watchBudget && isBudgetNaN ? (
              <>
                {budgetFormatter(budgetData.guarantied)} x{' '}
                {isEntryNaN(watchIncentive?.weAre8Fee) ? +watchIncentive?.weAre8Fee : '-'} ={' '}
                {numberToCurrency(budgetData.distributions)} WeAre8 fee
                {t(
                  ' (covers mobile partnerships, media partnerships, targeting engine, reporting, user and content moderation, data security, payment infrastructure).'
                )}
                <b>
                  {t(' This includes ')}
                  {numberToCurrency(
                    percentageOfBudget(CARBON_OFFSET_IMPACT_PERCENTAGE, stringToNumber(watchBudget))
                  )}
                  {t(' contribution to offset ')}
                  {carbonOffsetInTones(stringToNumber(watchBudget), brandInfo.countryCode)}T
                  {t(' of carbon via ')}
                  <a href="https://ecologi.com/">Ecologi</a>
                  {t(
                    " project. Making this campaign carbon negative and reducing your company's carbon footprint!"
                  )}
                </b>
              </>
            ) : (
              ''
            )}
          </div>
          <div className={s['allocation__total']}>
            <T
              src="Total : {total}"
              total={watchBudget && isBudgetNaN ? numberToCurrency(budgetData.total) : ''}
            />
          </div>
        </InfoCard>
      </div>
    </>
  );
};

export default RegularCampaign;
